.login.login-1 .login-aside {
    background: #F2C98A; }
    .login.login-1 .login-aside h3 {
      color: #986923; }
  
    .login.login-1 .login-aside {
      width: 100%;
      max-width: 650px; }
    
    
  
  @media (max-width: 992px) {
    .login.login-1 .login-aside {
      display: none !important
    }
  
    .login-area-center {
      justify-content: normal !important;
    }
  }
  
  
  
  
    @media (max-width: 755px) {
      .login-area-form { 
        width: 95% !important;
      }
    }
  
      .full-lg {
        min-height: 100% !important;
      height: 100% !important;
      }
  
      .full-height {
        min-height: 100%;
      height: 100%;
      }
  
      .login-area-scroll {
        display: block !important; height: 100vh !important; width: 100% !important; overflow-y: scroll !important;
      }
  
      .login-area-form {
        width: 85%; padding: 15px;
      }
  
      .login-area-center {
        display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 16px;
      padding-left: 16px;
      margin: auto;
      
      }
